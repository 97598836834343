import * as React from "react";
import Box from "@material-ui/core/Box";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Topic({t}) {
  console.log(t);
  return (
    <Accordion>
           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
           <Box sx={{ width: 7,  mr: 1 }}></Box>
           <Typography variant="h6">{t.name}</Typography>
         </AccordionSummary>
         <AccordionDetails>
           <Typography sx={{ml: 3}}>
             {t.details}
           </Typography>
         </AccordionDetails>
      </Accordion>
  );
}
