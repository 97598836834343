import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#26C6F5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#B930E6",
      },
      tertiary: {
        main: "#23018F",
        contrastText: "#fff"
      },
      error: {
        main: "#EB3D3D",
      },
    },
    selectLabel: { display: "show" },
    typography: {
      fontFamily: "Urbanist, Arial",
      h2: {
        fontSize: 46,
        color: "#26C6F5",
      },
      h4: {
        color: "#26C6F5",
        fontSize: 30,
      },
      h5: {
        fontSize: 26,
        fontWeight: 300,
      },
      h6: {
        fontSize: 20,
        fontWeight: 500,
      },
      body1: {
        fontSize: 18,
        fontWeight: 300,
      },
      body2: {
        fontSize: 15,
        fontWeight: 300,
        color: "#444",
      },
      cardSection: {
        fontSize: 26,
        fontWeight: 200,
        color: "#222",
      },
      cardLabel: {
        fontSize: 18,
        fontWeight: 800,
        color: "#222",
      },
      mealOrder: {
        fontSize: 12,
        fontWeight: 200,
        color: "#222",
        lineHeight:1,
        textAlign: "center"
      },
      mealTaken: {
        fontSize: 14,
        fontWeight: 200,
        color: "#000",
        textAlign: "center"
      },
    },
  })
);
