import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core/Box";
import { useProjectsContext } from "../contexts/ProjectsContext";

export default function ManageTopics() {
  const { projectForm, setProjectForm, topicsNameObj } = useProjectsContext();

  const handleChange = (name, value) => {
    const tempTopics = [];
    projectForm.topics.forEach((t) => {
      let tempObj = t;
      if (t.name === name) {
        tempObj.description = value;
      }
      tempTopics.push(tempObj);
    });
    setProjectForm(() => {
      return { ...projectForm, topics: tempTopics };
    });
  };

  return (
    <Box>
      <Typography variant="h6">Manage Canon Topics</Typography>
      {projectForm &&
        projectForm.topics &&
        projectForm.topics.map((t) => (
          <Box key={t.name}>
            <Typography sx={{ mt: 2 }}>
              <b>{t.name}</b>
            </Typography>
            <Typography sx={{ mt: 2 }}>{topicsNameObj[t.name].details}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Explain how this topic will be covered in your project:
            </Typography>
            <TextField
              autoFocus
              id="name"
              type="text"
              value={t.description}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(e) => handleChange(t.name, e.target.value)}
            />
          </Box>
        ))}
    </Box>
  );
}
