import React, { useContext, createContext, useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { arrangeTopics } from "../../shared/libraries/functions";

const CanonContext = createContext();

export function useCanonContext() {
  return useContext(CanonContext);
}

export function CanonProvider({ children }) {
  const [topics, setTopics] = useState();

  const getTopics = async () => {
    const topicsRef = collection(db, "topics");
    const q = query(topicsRef, orderBy("strand"));
    const snapshot = await getDocs(q);
    const topics = arrangeTopics(snapshot);
    setTopics(topics);
  };

  useEffect(() => {
    getTopics();
  }, []);

  const value = {
    topics,
  };
  return <CanonContext.Provider value={value}>{children}</CanonContext.Provider>;
}
