import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../shared/Navigation/Navbar";
import { Typography } from "@material-ui/core";
import CanonContainer from "../components/CanonContainer";
import { CanonProvider } from "../contexts/CanonContext";

export default function ViewCanon(){
  return (
    <CanonProvider>
      <NavBar></NavBar>
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: "64px" }}>
        <Typography
          sx={{ pt: 2, marginBottom: "10px" }}
          variant="h3"
        >
          Canon
        </Typography>
        <CanonContainer />
      </Container>
    </CanonProvider>
  );
};