import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ManageTopics from "./ManageTopics";
import HomeroomSelect from "./HomeroomSelect";
import { useProjectsContext } from "../contexts/ProjectsContext";
import TopicSelect from "./TopicSelect";

export default function NewProjectDialog() {
  const { projectForm, newProjectOpen, setNewProjectOpen, setProjectForm, saveNewForm } =
    useProjectsContext();

  const handleDialogClose = () => {
    setNewProjectOpen(false);
  };

  const handleChange = (field, value) => {
    setProjectForm(() => {
      return { ...projectForm, [field]: value };
    });
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={newProjectOpen} onClose={handleDialogClose}>
      <form onSubmit={saveNewForm}>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Project Name</Typography>
          <TextField
            autoFocus
            id="name"
            type="text"
            required
            value={projectForm.name}
            fullWidth
            variant="outlined"
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Project Description
          </Typography>
          <TextField
            autoFocus
            id="name"
            type="text"
            required
            value={projectForm.description}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            onChange={(e) => handleChange("description", e.target.value)}
          />
          <HomeroomSelect />
          <ManageTopics />
          <TopicSelect />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
