import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalProvider } from "./contexts/GlobalContext";
import { routes } from "./Routes";
import { theme } from "./Theme";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <GlobalProvider>
          <ThemeProvider theme={theme}>
            <main className="root">{routes}</main>
          </ThemeProvider>
        </GlobalProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
