import React, {useState} from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import useWindowDimensions from "../../shared/useWindowDimensions";
import { useProjectsContext } from "../contexts/ProjectsContext";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function TopicSelect() {
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const [topicName, setTopicName] = useState([]);
  const { topicsForSelect, setProjectForm, projectForm } =
    useProjectsContext();


  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: (height * 0.6),
        width: width*.5,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const tempArray = [];

    if(event.target.value){
        event.target.value.forEach((t)=>{
            tempArray.push({name: t, description: ""})
        })
    }

    setProjectForm(() => {
      return { ...projectForm, topics: tempArray };
    });
    setTopicName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      {topicsForSelect && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Select Topics
          </Typography>
          <Select
            id="demo-multiple-chip"
            multiple
            value={topicName}
            onChange={handleChange}
            input={<OutlinedInput fullWidth id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {topicsForSelect &&
              topicsForSelect.map((t) =>
                t.type === "strand" ? (
                  <ListSubheader key={t.name}>{t.name}</ListSubheader>
                ) : (
                  <MenuItem
                    key={t.name}
                    value={t.name}
                    style={getStyles(t.name, topicName, theme)}
                  >
                    {t.name}
                  </MenuItem>
                )
              )}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
