import React, { useContext, useState, useEffect } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, db, provider } from "../firebase";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [authUser, setAuthUser] = useState(false);
  const [staff, setStaff] = useState();
  const [staffObj, setStaffObj] = useState();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then(async (result) => {
      setLoginLoading(true);
      result.user.email.split("@")[1] === "thegatheringplacek12.org" ||
        auth.signOut();
    });
  };

  function logout() {
    setCurrentUser(null);
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setAuthUser(true);
        validateUser(user);
      } else {
        setAuthUser(false);
      }
      setLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      setLoginLoading(false);
    }
  }, [currentUser]);


  const validateUser = async (user) => {
    const response = await fetch(
      "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/staff",
      {
        headers: {
          authorization: process.env.REACT_APP_FIREBASE_API_KEY,
        },
      }
    );
    const allStaff = await response.json();
    setStaff(allStaff);
    const tempObj = {};
    allStaff.forEach((s) => {
      tempObj[s.id] = `${s.firstName} ${s.lastName}`
      if (s.id === user.email) {
        setCurrentUser(s);
      }
    });
    setStaffObj(tempObj);
  };

  const value = {
    currentUser,
    loginLoading,
    signInWithGoogle,
    logout,
    auth,
    authUser,
    loading,
    staff,
    staffObj
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
