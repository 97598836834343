import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../shared/Navigation/Navbar";
import { Typography } from "@material-ui/core";
import ProjectsContainer from "../components/ProjectsContainer";
import { ProjectsProvider } from "../contexts/ProjectsContext";

export default function Projects(){
  return (
    <ProjectsProvider>
      <NavBar></NavBar>
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: "64px" }}>
        <Typography
          sx={{ pt: 2, marginBottom: "10px" }}
          variant="h3"
        >
          Projects
        </Typography>
        <ProjectsContainer />
      </Container>
    </ProjectsProvider>
  );
};