export const arrangeTopics = (snapshot) => {
  const tempArray = [];
  let tempObj = { strand: "", topics: [] };
  let first = true;
  snapshot.forEach((s) => {
    if (s.data().strand === tempObj.strand) {
      tempObj.topics.push({ id: s.id, ...s.data() });
    } else {
      if (!first) {
        tempArray.push(tempObj);
      }
      first = false;
      tempObj = { strand: s.data().strand, topics: [] };
      tempObj.topics.push({ id: s.id, ...s.data() });
    }
  });
  tempArray.push(tempObj);
  return tempArray;
};
