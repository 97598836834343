import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useProjectsContext } from "../contexts/ProjectsContext";

export default function ManageRubric() {
  const { editCanonDesc, manageRubricOpen, setManageRubricOpen, setEditCanonDesc, saveCanonEdit } =
    useProjectsContext();

  const handleDialogClose = () => {
    setManageRubricOpen(false);
  };

  const handleChange = (e) => {
    setEditCanonDesc(() => {
      return { ...editCanonDesc, description: e.target.value };
    });
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={manageRubricOpen} onClose={handleDialogClose}>
      <form onSubmit={saveCanonEdit}>
        <DialogTitle>Manage Rubric</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
