import React from "react";
import Card from "@mui/material/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useProjectsContext } from "../contexts/ProjectsContext";

export default function TopicCard({ t, projectID }) {
  const { topicsObj, setEditCanonOpen, setEditCanonDesc, setManageRubricOpen, manageRubricOpen } =
    useProjectsContext();

  const handleEdit = () => {
    setEditCanonDesc({ ...t, projectID: projectID });
    setEditCanonOpen(true);
  };

  const handleRubric = () => {
    setManageRubricOpen(true);
  };
  return (
    <>
      {topicsObj && (
        <Card sx={{ margin: 1 }}>
          <CardContent>
            <div key={t.id}>
              <Typography sx={{ mt: 2 }}>
                <b>{topicsObj[t.id].strand}: </b>
                {topicsObj[t.id].name}
              </Typography>
              <Typography sx={{ mt: 2 }}>{topicsObj[t.id].details}</Typography>
              <Typography sx={{ mt: 2 }} variant="body2">
                {t.description}
              </Typography>
            </div>
          </CardContent>
          <CardActions>
            <Box sx={{ ml: "auto" }}>
              <Button onClick={handleRubric} variant="contained" sx={{ ml: 1, mr: 1 }}>
                Add Rubric
              </Button>
              <Button
                onClick={handleEdit}
                variant="contained"
                color="secondary"
                sx={{ ml: 1, mr: 1 }}
              >
                Edit
              </Button>
              <Button variant="contained" color="error" sx={{ ml: 1, mr: 1 }}>
                Delete
              </Button>
            </Box>
          </CardActions>
        </Card>
      )}
    </>
  );
}
