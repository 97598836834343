import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../contexts/AuthContext";
import StudentCard from "./StudentCard";
import TopicCard from "./TopicCard";
import { useGlobalContext } from "../../contexts/GlobalContext";

export default function ProjectCard({ p }) {
  const { staffObj } = useAuth();
  const { studentObj } = useGlobalContext();
  return (
    <>
      {staffObj && studentObj && (
        <Card sx={{ minWidth: 275, mb: 2, backgroundColor: "#fdfdfd" }}>
          <CardContent>
            <Typography variant="h5" sx={{ mb: 2 }}>
              <b>Project Title: </b> {p.name}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <b>Lead Designer </b> {staffObj[p.owner]}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <b>Created At: </b> {p.createdAt.toLocaleDateString()}
            </Typography>
            <Typography>{p.description}</Typography>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Canon Topics:
            </Typography>
            {p.topics.map((t) => (
              <TopicCard key={t.id} t={t} projectID={p.id} />
            ))}
            <Typography variant="h5" sx={{ mt: 2 }}>
              Students:
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {p.students.map((s) => (
                <Grid item key={s} sm={3}>
                  <StudentCard s={s} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
