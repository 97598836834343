import React from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useProjectsContext } from "../contexts/ProjectsContext";
import { useGlobalContext } from "../../contexts/GlobalContext";

export default function HomeroomSelect() {
  const { projectForm, setProjectForm } = useProjectsContext();
  const { homerooms } = useGlobalContext();

  const handleChange = (field, value) => {
    setProjectForm(() => {
      return { ...projectForm, [field]: value };
    });
  };

  return (
    <React.Fragment>
      {homerooms && (
        <React.Fragment>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Select Class
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={projectForm.homeroom}
            fullWidth
            onChange={(e) => handleChange("homeroom", e.target.value)}
          >
            {homerooms.map((h) => (
              <MenuItem key={h.id} value={h.id}>
                {h.name}
              </MenuItem>
            ))}
          </Select>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
