import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ViewCanon from "./ManageCanon/pages/ViewCanon";
import StrandsUpload from "./Uploads/StrandsUpload";
import TopicsUpload from "./Uploads/TopicsUpload";
import Projects from "./Projects/pages/Projects"

import Login from "./auth/Login";

export const routes = (
  <Switch>
    <Route exact path="/" component={ViewCanon} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/canon" component={ViewCanon} />
    <PrivateRoute exact path="/strandsUpload" component={StrandsUpload} />
    <PrivateRoute exact path="/topicsUpload" component={TopicsUpload} />
    <PrivateRoute exact path="/projects" component={Projects} />
  </Switch>
);
