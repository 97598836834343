import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { useAuth } from "../contexts/AuthContext";
import Box from "@material-ui/core/Box";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="primary" to="tgpk12.org">
        The Gathering Place
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "8em",
  },
  avatar: {
    margin: "1em",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "1em",
  },
  submit: {
    margin: "8em",
  },
}));

export default function Login() {
  const classes = useStyles();
  const { currentUser, signInWithGoogle, loginLoading, authUser } = useAuth();
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   if (authUser) {
  //     history.push("/canon");
  //   }else{
  //     history.push("/")
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser]);

  return (
    <Container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
      <CssBaseline />
      {loginLoading ? (
        <div className={classes.paper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.paper}>
          <Avatar
            className={classes.avatar}
            sx={{ width: 200, height: 200 }}
            src="../TGPicon.png"
          ></Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={signInWithGoogle}
            sx={{ marginTop: "2em" }}
          >
            Sign In With Google
          </Button>
        </div>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
