import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@material-ui/core/Container";
import NavBar from "../shared/Navigation/Navbar";
import { collection, doc, updateDoc, getDoc, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import Papa from "papaparse";

//TODO: Have not checked since switching to students table

export default function StrandsUpload(){
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const uploadStrand = async (value, index, last) => {
    const strandsRef = collection(db, "strands");
    const snapshot = await getDocs(strandsRef);
    let skip = false;
    snapshot.forEach((s) => {
      if (s.data().name === value.name) {
        skip = true;
      }
    });
    if (!skip) {
      addDoc(strandsRef, {
        name: value.name ? value.name : "",
        description: value.description ? value.description : "",
        topics: [],
      });
    }

    if (last) {
      setLoading(false);
      setSuccessMessage("Your File Has Been Uploaded!");
    }
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      setLoading(true);
      Papa.parse(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          let last = false;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              last = true;
            }
            uploadStrand(value, index, last);
          });
        },
        header: true,
      });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "1em",
          }}
        >
          <Avatar sx={{ width: 120, height: 120, mb: 3 }} src="../TGPicon.png" />
        </Box>
        <Paper>
          <Box sx={{ textAlign: "center", pt: 3 }}>
            <Typography variant="h1">Upload Strands</Typography>
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                m: 2,
                mb: 2,
              }}
            >
              <CircularProgress size={60} sx={{ mb: 2 }} />
            </Box>
          ) : (
            <Box sx={{ mt: 3, textAlign: "center" }}>
              {successMessage && <Typography variant="h4">{successMessage}</Typography>}
              <input
                id="uploadFile"
                type="file"
                name="file"
                style={{ display: "none" }}
                onChange={changeHandler}
              />
              <label htmlFor="uploadFile">
                {" "}
                <Button variant="contained" sx={{ margin: 3 }} component="span">
                  Choose File
                </Button>
              </label>
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 3 }}
                onClick={() => {
                  handleSubmission();
                }}
              >
                Submit
              </Button>
              {selectedFile && <Typography>{selectedFile.name}</Typography>}
            </Box>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
};
