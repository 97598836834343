import React, { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { useAuth } from "./AuthContext";

const GlobalContext = React.createContext();

export function useGlobalContext() {
  return useContext(GlobalContext);
}

export function GlobalProvider({ children }) {
  const [homerooms, setHomerooms] = useState();
  const [homeroomObj, setHomeroomObj] = useState();
  const [students, setStudents] = useState();
  const [studentObj, setStudentObj] = useState();
  const [selectedHomeroom, setSelectedHomeroom] = useState(null);
  const { currentUser } = useAuth();

  const getHomerooms = async () => {
    const homeroomObject = {};
    const homeroomArray = [];
    const homeroomResponse = await fetch(
      "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/homerooms",
      {
        headers: {
          authorization: process.env.REACT_APP_FIREBASE_API_KEY,
        },
      }
    );
    const homerooms = await homeroomResponse.json();
    homerooms.forEach((homeroom) => {
      homeroomObject[homeroom.id] = homeroom.name;
      homeroomArray.push({ ...homeroom });
    });
    setHomerooms(homeroomArray);
    setHomeroomObj(homeroomObject);
  };

  const getStudents = async () => {
    const studentObj = {};
    const studentsArray = [];
    const studentResponse = await fetch(
      "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/students",
      {
        headers: {
          authorization: process.env.REACT_APP_FIREBASE_API_KEY,
        },
      }
    );
    const students = await studentResponse.json();
    students.forEach((s) => {
      if (s.enrollStatus === "Actively Enrolled") {
        studentObj[s.id] = {...s}
        studentsArray.push({ ...s });
      }
    });
    setStudents(studentsArray);
    setStudentObj(studentObj);
  };

  useEffect(() => {
    getHomerooms();
    getStudents();
  }, []);

  const value = {
    selectedHomeroom,
    setSelectedHomeroom,
    homerooms,
    students,
    studentObj,
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
}
