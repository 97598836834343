import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import ProjectCard from "./ProjectCard";
import NewProjectDialog from "./NewProjectDialog";
import EditCanonDesc from "./EditCanonDesc";
import ManageRubric from "./ManageRubric";
import { useProjectsContext } from "../contexts/ProjectsContext";

export default function ProjectsContainer() {
  const { projects, setNewProjectOpen } = useProjectsContext();

  const handleNewProjectOpen = () => {
    setNewProjectOpen(true);
  };
  return (
    <React.Fragment>
      {projects && projects.map((p) => <ProjectCard key={p.id} p={p} />)}

      <Fab
        sx={{ position: "fixed", bottom: "5vh", right: "5vw" }}
        color="primary"
        aria-label="add"
        onClick={() => handleNewProjectOpen()}
      >
        <AddIcon />
      </Fab>
      <NewProjectDialog />
      <EditCanonDesc />
      <ManageRubric />
    </React.Fragment>
  );
}
