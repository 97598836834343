import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@material-ui/core/Box";
import Topic from "./Topic";
import {colorArray} from "../libraries/props"
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Strand({ s, i }) {
  

  return (
    <Accordion sx={{ backgroundColor: "#fbfbfb" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ width: 7, backgroundColor: colorArray[i].main, mr: 1 }}></Box>
        <Typography variant="h5">{s.strand}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {s.topics.map((t, index) => (
          <Topic t={t} key={`topic${index}`}  />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
