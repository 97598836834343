import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useProjectsContext } from "../contexts/ProjectsContext";

export default function EditCanonDesc() {
  const { editCanonDesc, editCanonOpen, setEditCanonOpen, setEditCanonDesc, saveCanonEdit } =
    useProjectsContext();

  const handleDialogClose = () => {
    setEditCanonOpen(false);
  };

  const handleChange = (e) => {
    setEditCanonDesc(() => {
      return {...editCanonDesc, description: e.target.value};
    });
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={editCanonOpen} onClose={handleDialogClose}>
    {editCanonDesc && (
      <form onSubmit={saveCanonEdit}>
        <DialogTitle>Edit Canon Description</DialogTitle>
        <DialogContent>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Project Description
          </Typography>
          <TextField
            autoFocus
            id="name"
            type="text"
            required
            value={editCanonDesc.description}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </form>
      )}
    </Dialog>
  );
}