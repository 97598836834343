import React from "react";
import { Link } from "react-router-dom";

export default function WebAdmins() {
  return (
    <>
      {/* <Link to="/canon" className="navLink">
        Canon
      </Link>
      <Link to="/projects" className="navLink">
        Projects
      </Link>
      <Link to="/topicsUpload" className="navLink">
        Topics Upload
      </Link> */}
    </>
  );
}
