import React, { useContext, createContext, useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  orderBy,
  Timestamp,
  addDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { arrangeTopics } from "../../shared/libraries/functions";
import { useAuth } from "../../contexts/AuthContext";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { blankForm } from "../libraries/props";

const ProjectsContext = createContext();

export function useProjectsContext() {
  return useContext(ProjectsContext);
}

export function ProjectsProvider({ children }) {
  const { currentUser } = useAuth();
  const { students } = useGlobalContext();
  const [projects, setProjects] = useState();
  const [topics, setTopics] = useState();
  const [topicsObj, setTopicsObj] = useState();
  const [topicsNameObj, setTopicsNameObj] = useState();
  const [topicsForSelect, setTopicsForSelect] = useState();
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [editCanonOpen, setEditCanonOpen] = useState(false);
  const [manageRubricOpen, setManageRubricOpen] = useState(false);
  const [editCanonDesc, setEditCanonDesc] = useState();
  const [projectForm, setProjectForm] = useState(blankForm);
  const [triggerProjects, setTriggerProjects] = useState(false);

  const getProjects = async () => {
    const projectsRef = collection(db, "projects");
    const snap = await getDocs(projectsRef);
    const tempArray = [];
    snap.forEach((s) => {
      const createdAt = s.data().createdAt.toDate();
      tempArray.push({ id: s.id, ...s.data(), createdAt: createdAt });
    });
    setProjects(tempArray);
  };

  const getTopics = async () => {
    const topicsRef = collection(db, "topics");
    const q = query(topicsRef, orderBy("strand"));
    const snapshot = await getDocs(q);
    const topics = arrangeTopics(snapshot);
    setTopics(topics);
    const tempArray = [];
    const tempObj = {};
    const tempNameObj = {};
    topics.forEach((s) => {
      tempArray.push({ type: "strand", name: s.strand });
      s.topics.forEach((t) => {
        tempArray.push({ type: "topic", name: t.name, details: t.details, id: t.id });
        tempObj[t.id] = t;
        tempNameObj[t.name] = t;
      });
    });
    setTopicsObj(tempObj);
    setTopicsNameObj(tempNameObj);
    setTopicsForSelect(tempArray);
  };

  //****************************** Save Forms ****************************** */

  const saveNewForm = async (e) => {
    e.preventDefault();
    const createdAt = Timestamp.now();
    const topicsArray = [];
    topics.forEach((s) => {
      s.topics.forEach((t) => {
        projectForm.topics.forEach((selectedTopic) => {
          if (t.name === selectedTopic.name) {
            topicsArray.push({ id: t.id, description: selectedTopic.description });
          }
        });
      });
    });

    const studentArray = [];
    students.forEach((s) => {
      if (s.homeroom === projectForm.homeroom) {
        studentArray.push(s.id);
      }
    });

    const projectRef = collection(db, "projects");
    await addDoc(projectRef, {
      ...projectForm,
      createdAt: createdAt,
      owner: currentUser.id,
      topics: topicsArray,
      students: studentArray,
    });
    setNewProjectOpen(false);
    setProjectForm(blankForm);
  };

  const saveCanonEdit = async (e) => {
    e.preventDefault();
    const projectRef = doc(db, "projects", editCanonDesc.projectID);
    const projectSnap = await getDoc(projectRef);
    const tempArray = [];
    projectSnap.data().topics.forEach((t) => {
      if (t.id === editCanonDesc.id) {
        tempArray.push({ ...t, description: editCanonDesc.description });
      } else {
        tempArray.push(t);
      }
    });
    await updateDoc(projectRef, {
      topics: tempArray,
    });
    setEditCanonOpen(false);
    setEditCanonDesc("");
    setTriggerProjects(!triggerProjects);
  };

  useEffect(() => {
    getTopics();
  }, []);

  useEffect(() => {
    getProjects();
  }, [newProjectOpen, triggerProjects]);

  const value = {
    projects,
    newProjectOpen,
    setNewProjectOpen,
    projectForm,
    setProjectForm,
    topics,
    topicsForSelect,
    saveNewForm,
    topicsObj,
    topicsNameObj,
    editCanonOpen,
    setEditCanonOpen,
    editCanonDesc,
    setEditCanonDesc,
    saveCanonEdit,
    manageRubricOpen,
    setManageRubricOpen,
  };
  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
}
