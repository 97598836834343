import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useGlobalContext } from "../../contexts/GlobalContext";

export default function StudentCard({ s }) {
  const { studentObj } = useGlobalContext();
  return (
    <>
      {studentObj && (
        <Card sx={{ margin:1 }}>
          <CardContent>
                <Typography>
                  {studentObj[s].childFirstName}
                  {studentObj[s].childLastName}
                </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
}
