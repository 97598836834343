import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function MobileMenu({ handleClose, setAnchorEl, anchorEl }) {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div>
      <IconButton
        size="large"
        color="inherit"
        onClick={handleClick}
        aria-controls="scollapse-menu"
        aria-haspopup="true"
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="collapse-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/canon" className="navLinkMenu">
            Canon
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
