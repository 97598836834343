import React from 'react';
import Strand from "./Strand"
import {useCanonContext} from "../contexts/CanonContext"


export default function CanonContainer() {
    const {topics} = useCanonContext();
  return (
    <div>
      {topics && topics.map((s, i)=>(
          <Strand s={s} i={i} key={s.strand}/>
      ))}
    </div>
  );
}